
import { Component, Vue } from 'vue-property-decorator';
import TimeRangeFilterInfo from '@/components/Charts/widgetInfo/TimeRangeFilter.vue';
import ShiftFilterInfo from '@/components/Charts/widgetInfo/ShiftFilter.vue';
import DownloadDataInfo from './DownloadData.vue';
import TargetFilterInfo from './TargetFilter.vue';
import GroupXAxisByInfo from './GroupXAxisBy.vue';
import AggregatesInfo from './Aggregates.vue';
import ChangeDisplayInfo from './ChangeDisplay.vue';

@Component({
  components: {
    'time-range-filter': TimeRangeFilterInfo,
    'shift-filter': ShiftFilterInfo,
    'download-data': DownloadDataInfo,
    'group-x-axis-by': GroupXAxisByInfo,
    aggregates: AggregatesInfo,
    'change-display': ChangeDisplayInfo,
  },
})
export default class TubeCuttingTimeHist extends Vue {}
